import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Header from 'components/Header/Header'
import CookieBanner from 'components/CookieBanner/CookieBanner'
import Footer from 'components/Footer/Footer'
import { trackGAEvents, untrackGAEvents } from 'utils/events'

import 'styles/base.scss'

const Layout = ({ location, children, title, announcements }) => {
  useEffect(() => {
    trackGAEvents()
    return untrackGAEvents
  }, [])
  return (
    <Fragment>
      <CookieBanner />
      <Header
        location={location}
        meta={[
          {
            name: 'google-site-verification',
            content: 'UCOVOuQEFHCbjehIlL5q4R3DnKTSqEWYvQktaDSLMqw',
          },
        ]}
      />
      <Helmet title={title || 'Hackney Council'}>
        <html lang="en" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {announcements}
      <article id="main-content" role="main" tabIndex="-1">
        {children}
      </article>
      <Footer />
    </Fragment>
  )
}

Layout.propTypes = {
  location: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  announcements: PropTypes.node,
}

export default Layout
