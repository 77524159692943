/* global ga */

export const trackGAEvents = () =>
  document.body.addEventListener('click', checkForGATracker)

export const untrackGAEvents = () =>
  document.body.removeEventListener('click', checkForGATracker)

const checkForGATracker = e => {
  if (typeof ga !== undefined) {
    const eventCategory = e.target.getAttribute('data-event-category')
    const eventAction = e.target.getAttribute('data-event-action')
    if (eventCategory && eventAction) {
      const eventLabel = e.target.getAttribute('data-event-label')
      ga('send', {
        hitType: 'event',
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel ? eventLabel : '',
      })
    }
  }
}

export default {
  trackGAEvents,
  untrackGAEvents,
}
