import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { StaticQuery, graphql } from 'gatsby'

import SocialIcon from 'components/SocialIcon/SocialIcon'

import styles from './SocialLinks.module.scss'

const query = graphql`
  query SocialLinks {
    wordpressAcfOptions {
      options {
        social_accounts {
          name
          link
        }
      }
    }
  }
`

export const SocialLinks = ({
  className,
  wordpressAcfOptions: {
    options: { social_accounts },
  },
}) => (
  <ul className={cx(styles.icons, className)}>
    {social_accounts.map(({ name, link }) => (
      <li key={name}>
        <a href={link}>
          <SocialIcon name={name} />
          <span className={styles.iconText}>{name}</span>
        </a>
      </li>
    ))}
  </ul>
)

SocialLinks.propTypes = {
  className: PropTypes.string,
  wordpressAcfOptions: PropTypes.shape({
    options: PropTypes.shape({
      social_accounts: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

const SocialLinksWithStaticQuery = props => (
  <StaticQuery
    query={query}
    render={data => SocialLinks({ ...data, ...props })}
  />
)

export default SocialLinksWithStaticQuery
