/* eslint no-unused-vars: 0 */ // --> OFF
import React from 'react'
import ScriptTag from 'react-script-tag'

// const COOKIE_STORAGE_NAME = 'cookiebanner'

const CookieBanner = () => {
  const config = {
    env: 'test',
    apiKey: process.env.REACT_APP_COOKIE_CONTROL_KEY,
    product: process.env.REACT_APP_COOKIE_CONTROL_PRODUCT,
  }
  console.log('***************** Config Values ******************')
  console.log(JSON.stringify(config))
  return (
    <ScriptTag
      type="text/javascript"
      src="https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js"
    />
  )
}

export default CookieBanner
