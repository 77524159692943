import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import { navigate } from '@reach/router'
import { FaSearch } from 'react-icons/fa'

import LogoHackney from 'assets/LogoHackney'
import BurgerDesktop from 'assets/burger-menu/desktop.svg'
import BurgerDesktopClosed from 'assets/burger-menu/desktop-closed.svg'
import BurgerMobile from 'assets/burger-menu/mobile.svg'
import BurgerMobileClosed from 'assets/burger-menu/mobile-closed.svg'
import Link from 'components/Link/Link'
import SkipLinks from 'components/SkipLinks/SkipLinks'
import styles from './Header.module.scss'

const query = graphql`
  query HeadingQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export const Header = ({ location: { pathname } }) => {
  const isMenuPage = pathname.includes('/menu')
  const menuNavigate = isMenuPage ? -1 : '/menu'
  return (
    <header className={styles.container} role="banner">
      <SkipLinks />
      <div className={styles.inner}>
        <Link to="/" className={cx(styles.homeButton, 'white')} id="top">
          <LogoHackney suffix="header" />
        </Link>
        <button
          onClick={() =>
            navigate(menuNavigate, {
              state: { prevPage: pathname.substring(1) },
            })
          }
          className={styles.menuButton}
        >
          <span>Menu</span>
          {isMenuPage ? (
            <Fragment>
              <BurgerDesktopClosed
                className={styles.burgerDesktop}
                alt="close menu"
              />
              <BurgerMobileClosed
                className={styles.burgerMobile}
                alt="close menu"
              />
            </Fragment>
          ) : (
            <Fragment>
              <BurgerDesktop className={styles.burgerDesktop} alt="open menu" />
              <BurgerMobile className={styles.burgerMobile} alt="open menu" />
            </Fragment>
          )}
        </button>
        <Link to="/search" className={styles.searchButton}>
          <FaSearch title="Search" />
        </Link>
      </div>
      <div className={styles.linesContainer}>
        <div className={styles.lines} />
      </div>
    </header>
  )
}

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  site: PropTypes.shape({
    siteMetadata: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

const HeaderWithStaticQuery = props => (
  <StaticQuery query={query} render={data => Header({ ...data, ...props })} />
)

export default HeaderWithStaticQuery
