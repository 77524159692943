import React from 'react'
import PropTypes from 'prop-types'
import { FaTwitter, FaYoutube, FaFacebookF, FaInstagram } from 'react-icons/fa'

const SOCIAL_ICONS = {
  facebook: FaFacebookF,
  twitter: FaTwitter,
  youtube: FaYoutube,
  instagram: FaInstagram,
}

const SocialIcon = ({ name, ...props }) => {
  const SocialIcon = SOCIAL_ICONS[name]
  return SocialIcon ? <SocialIcon {...props} /> : null
}

SocialIcon.propTypes = {
  name: PropTypes.string.isRequired,
}

export default SocialIcon
