import React from 'react'
import Link from 'components/Link/Link'

import styles from './SkipLinks.module.scss'

const SkipLinks = () => {
  return (
    <div className={styles.lbhSkipLink}>
      <Link className={styles.lbhSkipLink__link} to="#main-content">
        Skip to main content
      </Link>
    </div>
  )
}

export default SkipLinks
