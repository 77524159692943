/* eslint-disable jsx-a11y/no-onchange */
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Link, StaticQuery, graphql } from 'gatsby'
import { FaCaretDown } from 'react-icons/fa'

import SocialLinks from 'components/SocialLinks/SocialLinks'

import styles from './Footer.module.scss'
import gridStyles from 'styles/grid.module.scss'

import LogoHackney from 'assets/LogoHackney'

const query = graphql`
  query FooterQuery {
    wordpressWpMedia(slug: { glob: "ogl-symbol*" }) {
      source_url
    }
    wordpressAcfOptions {
      options {
        footer_links_left {
          text
          url
        }
        footer_links_middle {
          text
          url
        }
        footer_links_right {
          text
          url
        }
      }
    }
  }
`

const LinksSelect = links => (
  <div className={styles.linksSelect}>
    <label htmlFor="popular-links">
      Most popular <FaCaretDown />
    </label>
    <select
      name="links"
      id="popular-links"
      onChange={({ target: { value } }) => (window.location = value)}
    >
      {links.map(link => (
        <option key={link.text} value={link.url}>
          {link.text}
        </option>
      ))}
    </select>
  </div>
)

const ColumnLink = link => (
  <li className={styles.footerListItem} key={link.text}>
    <a href={link.url}>{link.text}</a>
  </li>
)

export const Footer = ({
  wordpressWpMedia,
  wordpressAcfOptions: {
    options: { footer_links_left, footer_links_middle, footer_links_right },
  },
}) => {
  const collapsibleLinks = [...footer_links_left, ...footer_links_middle]
  return (
    <footer className={styles.footer} role="contentinfo">
      <div className={styles.footerWrapper}>
        <div className={gridStyles.lbhContainer}>
          <div className={gridStyles.lbhRow}>
            <div
              className={cx(gridStyles.lbhColumnFull, styles.footerLogoWrapper)}
            >
              <Link to="/" className={cx(styles.footerLogoLink, 'white')}>
                <LogoHackney suffix="footer" />
              </Link>
            </div>
            <div className={styles.footerLinksAll}>
              {LinksSelect(collapsibleLinks)}
              <ul className={cx(styles.footerList, styles.collapsibleLinks)}>
                {collapsibleLinks.map(ColumnLink)}
              </ul>
              <ul className={styles.footerList}>
                {footer_links_right.map(ColumnLink)}
              </ul>
              <SocialLinks />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerOGL}>
        <div className={gridStyles.lbhContainer}>
          <div className={gridStyles.lbhRow}>
            <div className={gridStyles.lbhColumnFull}>
              <div className={styles.footerOglText}>
                <img
                  src={wordpressWpMedia.source_url}
                  className={styles.footerOglLogo}
                  alt="Open Government Logo"
                ></img>
                All content is available under the{' '}
                <a href="https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/">
                  Open Government Licence v3.0
                </a>
                , except where otherwise stated
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  wordpressWpMedia: PropTypes.shape({
    source_url: PropTypes.string.isRequired,
  }).isRequired,
  wordpressAcfOptions: PropTypes.shape({
    options: PropTypes.shape({
      footer_links_left: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
      footer_links_middle: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
      footer_links_right: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

const FooterWithStaticQuery = props => (
  <StaticQuery query={query} render={data => Footer({ ...data, ...props })} />
)

export default FooterWithStaticQuery
